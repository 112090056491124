// ============================================================================
// TeamManagerService
// ------------------
// TeamManager module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/TeamManagerApi'

import AppService from '@/services/AppService'

// ---------
// Internals
// ---------
const Private = {
	adminGroup: function () {
		return store.state.accountingFirm.selected?.isGroupAdmin
	}
}

// -------
// Exports
// -------
export default {
	getClients: function (accountingFirmId, param = {}) {
		return API.getClients(accountingFirmId, param)
			.promise.then(res => res.data.data)
			.then(clients => {
				return store.dispatch('clients/set', clients).then(() => {
					return clients
				})
			})
	},
	getRoles: function () {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getRoles(accountingFirmId).then(res => res.data.data)
	},
	getAccountants: function (accountingFirmId, param = {}) {
		return API.getAccountants(accountingFirmId, {
			params: param
		}).then(res => res.data.data)
	},
	getUserClients: function (accountingFirmId, accountant, params = {}) {
		return API.getUserClients(accountingFirmId, accountant.id, params).then(res => res.data)
	},
	addAccountant: function (accountingFirmId, accountant) {
		if (accountant.companies) {
			accountant.companies = accountant.companies.map(o => (typeof o == 'object' ? o.id : o))
		}
		accountant.accounting_firm_id = store.state.accountingFirm.selected.id
		return API.addAccountant(accountingFirmId, accountant).then(res => res.data.data)
	},
	updateAccountantAccess: function (accountingFirmId, accountant, data) {
		return API.updateAccountantAccess(accountingFirmId, accountant.id, data)
	},
	updateAccountantThemes: function (accountingFirmId, accountant, data) {
		return API.updateAccountantThemes(accountingFirmId, accountant.id, data)
	},
	updateAccountantRole: function (accountingFirmId, accountant) {
		let data = {
			role_id: accountant.role_id.role_id
		}
		return API.updateAccountantRole(accountingFirmId, accountant.id, data)
	},
	deleteAccountant: function (accountingFirmId, accountantId) {
		return API.deleteAccountant(accountingFirmId, accountantId)
	},
	sendMail: function (accountingFirmId, userId) {
		return API.sendMail(accountingFirmId, userId)
	},
	resetPasswordSendMail: AppService.resetPasswordSendMail,
	adminGroup: Private.adminGroup,
	listUsers: function (accountingFirmId) {
		return API.listUsers({
			accounting_firm_id: accountingFirmId
		}).promise.then(res => res.data.data)
	},
	getUserByEmail: function (email) {
		return API.getUserByEmail(email)
			.then(res => res.data.data)
			.then(users => users?.[0] ?? null)
	}
}
