<template>
	<v-combobox v-bind="$attrs" :filter="filter" :items="users" item-text="username" item-value="id" :label="$t(label)" :loading="loading" v-on="$listeners">
		<template v-slot:item="data">
			<w-list-tile-content :key="data.item.id">
				<w-list-tile-title>{{ data.item.username }}</w-list-tile-title>
				<w-list-tile-sub-title class="caption">{{ data.item.email }}</w-list-tile-sub-title>
			</w-list-tile-content>
		</template>
	</v-combobox>
</template>

<script>
import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'UserSelector',
	mixins: [TeamManagerModuleGuard],
	props: {
		default: {
			default: undefined,
			required: false,
			type: [Number, String]
		},
		label: {
			default: 'team-manager.user',
			required: false,
			type: String
		}
	},
	data: function () {
		return {
			loading: false,
			users: []
		}
	},
	watch: {
		accountingFirmId: {
			handler: function (newVal) {
				if (!newVal) {
					return
				}

				this.listUsers()
			},
			immediate: true
		}
	},
	methods: {
		filter: function (item, search) {
			const searchedTextRegEx = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')

			return item.username.match(searchedTextRegEx) || item.email.match(searchedTextRegEx)
		},
		listUsers: function () {
			this.loading = true

			return this.service
				.listUsers(this.accountingFirmId)
				.then(users => {
					this.users = users

					if (this.default) {
						this.setDefaultUser()
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		setDefaultUser: function () {
			if (!this.default) {
				return
			}

			let userId = this.default

			if (userId === 'me') {
				userId = this.userId
			}

			const foundUser = this.users.find(u => u.id === userId)

			if (!foundUser) {
				return
			}

			this.$emit('input', foundUser)
		}
	}
}
</script>
